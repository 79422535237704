<template>
  <div class="card-content detail-content">
    <Tables
      :items="listPurchaseHistorySubscriptionSP"
      :fields="fieldsSubscriptionSP"
      :itemsPerPageSelect="{}"
    >
      <template v-slot:created_at="{ item }">
        <td class="test">
          <div v-if="item.created_at !== null">
            {{ moment(new Date(item.created_at)).format("YYYY年MM月DD日") }}
          </div>
        </td>
      </template>
      <template v-slot:action="{ item }">
        <td class="btn-cancel" v-if="item.status_order != 3">
          <button
            class="btn btn-secondary"
            @click="
              cancelSubscriptionPayment(
                item.author_id,
                item.order_id,
                item.payment_type
              )
            "
          >
            解約
          </button>
        </td>
        <td v-else>解約済み</td>
      </template>
    </Tables>
  </div>
</template>

<script>
import { tableFields } from "../../utils/table-fields.js";
import Tables from "../../components/common/Tables";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "MyProfile",
  components: {
    Tables,
  },
  props: ["listPurchaseHistorySubscriptionSP"],
  data() {
    return {
      fieldsSubscriptionSP: tableFields.PURCHASE_USER_SUBSCRIPTION_SP,
    };
  },
  created() {},
  methods: {
    ...mapActions({
      cancelSubscriptionUser: "cancelSubscriptionUser",
    }),
    moment,
    cancelSubscriptionPayment(author_id, order_id, type) {
      const reg = {
        author_id: author_id,
        order_id: order_id,
        type: type === 2 ? "stripe" : "paypal",
      };
      this.cancelSubscriptionUser(reg);
    },
  },
};
</script>
